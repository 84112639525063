import React, { useEffect, useState } from 'react';
import './Portfolio.css';
import Zoom from 'react-reveal/Zoom';
import moi from "../../moi.png"
import arrow from "../../arrowRight.png"
import jpma from "../../pf/jpma.jpg"
import Project from '../Project/Project';
import projects from "../../projects.json"
import walletLink from "../../pf/walletLinkTezos.png"
import id360 from "../../pf/id360.png"
import discord from "../../pf/discord.png"
import stinkat from "../../pf/stinkat.png"
import walletProvider from "../../pf/walletProvider1.png"
import ProjectHigh from '../ProjectHigh/ProjectHigh';
import tracker from "../../pf/screenTracker.png"
import bettingcroc from "../../pf/bettingcroc2.png"


function Portfolio(props) {
    const [arrowClass, setArrowClass] = useState("")
    const handleScroll = () => {
        setArrowClass("none")
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div id='portfolio'>
            <div id='box1'>
                <div id='box2Text'>
                    <div id='box1Text'>
                        <p className='whiteP' id='achilleP'>Achille Dorier</p>
                        <p className='greyP box1P'>Node.js, React and Python</p>
                        <p className='greyP box1P'>Quick and reliable dev</p>
                    </div>
                    <img id='arrow' className={arrowClass} src={arrow}></img>

                </div>
                <div id='box1About'>
                    <img id='moi' src={moi}></img>
                    <p className='greyP' id='aboutP'>
                        {projects.resume}</p>
                    <a id='resume' href='/achilledorier.pdf'>Get my resume there</a>
                </div>
            </div>
            <p id='someOfMy'>My projects</p>
            <div id='projects'>
                <Zoom>
                    <ProjectHigh orientation="Left" image={tracker} title={projects.tracker.title} link={projects.tracker.link} github={projects.tracker.github} description={projects.tracker.description} technologies={projects.tracker.technologies} setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen} ></ProjectHigh>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={bettingcroc} title={projects.bettingcroc.title} link={projects.bettingcroc.link} github={projects.bettingcroc.github} description={projects.bettingcroc.description} technologies={projects.bettingcroc.technologies} setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Left"} image={stinkat} title={projects.stinkat.title} link={projects.stinkat.link} github={projects.stinkat.github} description={projects.stinkat.description} technologies={projects.stinkat.technologies} setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={walletProvider} title={projects.walletProvider.title} link={projects.walletProvider.link} github={projects.walletProvider.github} description={projects.walletProvider.description} technologies={projects.walletProvider.technologies} setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Left"} image={jpma} title={projects.jpma.title} link={projects.jpma.link} github={projects.jpma.github} description={projects.jpma.description} technologies={projects.jpma.technologies} id="jpma" setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={discord} title={projects.discordVerifier.title} link={projects.discordVerifier.link} github={projects.discordVerifier.github} description={projects.discordVerifier.description} technologies={projects.discordVerifier.technologies} id="discord" setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Left"} image={id360} title={projects.id360.title} link={projects.id360.link} github={projects.id360.github} description={projects.id360.description} technologies={projects.id360.technologies} setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={walletLink} title={projects.walletLink.title} link={projects.walletLink.link} github={projects.walletLink.github} description={projects.walletLink.description} technologies={projects.walletLink.technologies} setImage={props.setImage} setAltImage={props.setAltImage} setIsOpen={props.setIsOpen}></Project>
                </Zoom>
            </div>
        </div>
    )
}

export default Portfolio;
