import React, { useEffect, useState } from 'react';
import './App.css';
import Portfolio from './components/Portfolio/Portfolio';
import NavBar from './components/NavBar/NavBar'
import { ClickAwayListener } from '@mui/material';
import cross from "./close.png"
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState()
  const [altImage, setAltImage] = useState("")
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else if (!isOpen) {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen])
  return (
    <div className="App">
      <NavBar></NavBar>
      <Portfolio setIsOpen={setIsOpen} setImage={setImage} setAltImage={setAltImage}></Portfolio>
      {isOpen && (
        <div className="overlay" >
          <img id='crossOverlay' src={cross}></img>
          <ClickAwayListener onClickAway={() => { setIsOpen(false) }} touchEvent={false}>
            <img src={image} alt={altImage} className="fullsize-image" />
          </ClickAwayListener>
        </div>
      )}
    </div>
  )

}

export default App;
